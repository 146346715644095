/* eslint-disable no-undef */
var data = require('./properties.json');
if (process.env.NODE_ENV === 'dev') {
  services = {
    API_PROTOCOL: process.env.API_PROTOCOL,
    API_HOST: process.env.API_HOST,
    API_PORT: process.env.API_PORT,
    LANG: process.env.LANGUAGE,
  };
} else {
  var array = [];
  for (var i = 0; i < data[0].properties.length; i++) {
    var line = data[0].properties[i];
    if (i === 0) {
      array[i] = line.protocol;
    }
    if (i === 1) {
      array[i] = line.ip;
    }
    if (i === 2) {
      array[i] = line.port;
    }
    if (i === 3) {
      array[i] = line.language;
    }
  }
  services = {
    API_PROTOCOL: array[0],
    API_HOST: array[1],
    API_PORT: array[2],
    LANG: array[3],
  };
}
